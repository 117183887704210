// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("C:\\HOME\\edwinb\\Documents\\__CB1__\\website\\cb1at2k19\\site\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("C:\\HOME\\edwinb\\Documents\\__CB1__\\website\\cb1at2k19\\site\\src\\pages\\impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("C:\\HOME\\edwinb\\Documents\\__CB1__\\website\\cb1at2k19\\site\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\HOME\\edwinb\\Documents\\__CB1__\\website\\cb1at2k19\\site\\.cache\\data.json")

